<script>
import PenilaiLayout from "../../../layouts/penilai-layout.vue";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import Pagination from "@/router/layouts/table-pagination.vue";
import Datatable from "@/router/layouts/table-datatable.vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PenilaiLayout,
    Pagination,
    Datatable,
  },
  data() {
    return {
      title: "Asesmen HC - UNIT PELAKSANA SERTIFIKASI",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name_penilai,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      loadingTable: false,
      optionsTahun: [
        {
          label: 2022,
          value: 2022,
        },
        {
          label: 2023,
          value: 2023,
        },
        {
          label: 2024,
          value: 2024,
        },
        {
          label: 2025,
          value: 2025,
        },
        {
          label: 2026,
          value: 2026,
        },
      ],
      optionsBulan: [
        {
          label: "Januari",
          value: 1,
        },
        {
          label: "Februari",
          value: 2,
        },
        {
          label: "Maret",
          value: 3,
        },
        {
          label: "April",
          value: 4,
        },
        {
          label: "Mei",
          value: 5,
        },
        {
          label: "Juni",
          value: 6,
        },
        {
          label: "Juli",
          value: 7,
        },
        {
          label: "Agustus",
          value: 8,
        },
        {
          label: "September",
          value: 9,
        },
        {
          label: "Oktober",
          value: 10,
        },
        {
          label: "November",
          value: 11,
        },
        {
          label: "Desember",
          value: 12,
        },
      ],
      columns: [
        // { width: "5%", label: "ID Jadwal", name: "idjadwal" },
        { width: "5%", label: "No", name: "-" },
        { width: "10%", label: "Tanggal Mulai", name: "tanggal_mulai" },
        { width: "10%", label: "Tanggal Selesai", name: "tanggal_selesai" },
        { width: "auto", label: "Kompetensi", name: "nama_kompetensi" },
        { width: "10%", label: "level", name: "nama_level" },
        { width: "15%", label: "Status Penilaian", name: "status" },
        { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      ],
      listJadwalPenilai: [],
      tableFilter: {
        per_page: 10,
        page: 0,
        tahun: 0,
        bulan: 0,
      },
      paginationJadwalPenilai: {
        total: "",
        from: "",
        to: "",
        page: "",
        per_page: "",
        links: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
      },
    };
  },
  methods: {
    async getJadwalPenilai() {
      let user = JSON.parse(localStorage.user_penilai);
      this.loadingTable = true;
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      var config_axios = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "penilaian/jadwal",
        params: {
          nip: user?.nip ?? 1302070407650004,
          tahun: this.tableFilter.tahun,
          tanggal: this.tableFilter.bulan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token_penilai,
        },
      };
      await axios(config_axios)
        .then((response) => {
          let data = response.data.data;
          if (response.data.meta.code == 200) {
            this.listJadwalPenilai = data.data;
            this.paginationJadwalPenilai.total = data.total;
            this.paginationJadwalPenilai.from = data.from;
            this.paginationJadwalPenilai.to = data.to;
            this.paginationJadwalPenilai.links = data.links;
            this.paginationJadwalPenilai.lastPageUrl = data.last_page_url;
            this.paginationJadwalPenilai.nextPageUrl = data.next_page_url;
            this.paginationJadwalPenilai.prevPageUrl = data.prev_page_url;
            this.paginationJadwalPenilai.per_page = this.tableFilter.per_page;
            this.paginationJadwalPenilai.page = this.tableFilter.page;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.data.message,
            });
          }
          Swal.close();
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
        });
      this.loadingTable = false;
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      this.getJadwalPenilai();
    },
    pindahKeListPeserta(
      id_jadwal,
      id_kompetensi,
      id_level,
      nama_kompetensi,
      nama_level
    ) {
      let param_text =
        id_jadwal +
        "_" +
        id_kompetensi +
        "_" +
        id_level +
        "_" +
        nama_kompetensi +
        "_" +
        nama_level;
      let my_param = CryptoJS.AES.encrypt(
        param_text,
        "4lfahumaNeEd5ME!"
      ).toString();

      // Menggunakan arrow function untuk mempertahankan konteks this
      this.$router.push({
        name: "penilai-peserta",
        query: { peserta: my_param },
      });
    },
  },
  async mounted() {
    let d = new Date();
    this.tableFilter.bulan = d.getMonth() + 1;
    this.tableFilter.tahun = d.getFullYear();
    await this.getJadwalPenilai();
  },
};
</script>

<template>
  <PenilaiLayout>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="card mb-0" style="border-radius: 5px">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-between">
                  <div>
                    <div class="card-title">Asesmen HC</div>
                  </div>
                </div>
              </div>
              <hr class="mt-2" />
              <div class="row mb-4">
                <div class="form-group col-md-4">
                  <label for="statusTable">Tahun</label>
                  <select
                    class="form-control form-control-sm"
                    id="statusTable"
                    v-model="tableFilter.tahun"
                  >
                    <option
                      v-for="option in optionsTahun"
                      :value="option.value"
                      :key="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="statusTable">Bulan</label>
                  <select
                    class="form-control form-control-sm"
                    id="statusTable"
                    v-model="tableFilter.bulan"
                  >
                    <option
                      v-for="option in optionsBulan"
                      :value="option.value"
                      :key="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-2 d-flex align-items-end">
                  <div>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="getJadwalPenilai()"
                    >
                      <i class="fa fa-search me-1"></i>Filter
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row mb-2">
                <div class="col-4">
                  <table>
                    <tr>
                      <td>Show</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          id="showpaginatetable"
                          v-model="tableFilter.per_page"
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </td>
                      <td>Entries</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="tabelPenialaian">
                <div class="table-responsive mb-0" style="overflow-x: auto">
                  <datatable :columns="columns">
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="99" class="text-center">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr
                        v-else-if="
                          listJadwalPenilai == '' || listJadwalPenilai == []
                        "
                      >
                        <td class="text-center" colspan="6">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(data, index) in listJadwalPenilai"
                        :key="index"
                      >
<!--                        <td class="text-center">{{ data.idjadwal }}</td>-->
                        <td class="text-center">{{ paginationJadwalPenilai.from + index }}</td>
                        <td>{{ formatDate(data.tanggal_mulai) }}</td>
                        <td>{{ formatDate(data.tanggal_selesai) }}</td>
                        <td>{{ data.nama_kompetensi }}</td>
                        <td class="text-center">{{ data.nama_level }}</td>
                        <td class="text-center">
                          <span class="badge rounded-pull bg-warning" v-if="data.status == 1">Proses Penilaian</span>
                          <span class="badge rounded-pull bg-success" v-else-if="data.status == 2">Sudah Dinilai</span>
                          <span class="badge rounded-pull bg-danger" v-else>Belum Dinilai</span>
                        </td>
                        <td class="text-center">
                          <div class="d-grid">
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              @click="
                                pindahKeListPeserta(
                                  data.idjadwal,
                                  data.idkompetensi,
                                  data.level_kompetensi_idlevel_kompetensi,
                                  data.nama_kompetensi,
                                  data.nama_level
                                )
                              "
                            >
                              <i class="fa fa-clipboard-check me-1"></i>Mulai
                              Penilaian
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </datatable>
                </div>
                <Pagination
                  :pagination="paginationJadwalPenilai"
                  @to-page="toPage"
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PenilaiLayout>
</template>
<style scoped>
.nav-head {
  max-width: 1000px !important;
}

.search-pad {
  padding-left: 5px !important;
}

.input-group {
  display: flow;
}

.search-box {
  display: flow;
  align-items: center;
  background-color: #f8f8fb;
  border-radius: 40px;
}

.search-icon {
  position: static;
  line-height: 30px;
}

.search-input input {
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: unset;
  padding: 5px 0px 5px 15px;
}

.position-relative {
  position: relative;
}

.span-notif {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 8px;
}

.icon-notif {
  position: relative;
  top: 5px;
}

.nav-pills .nav-link {
  border-radius: 5rem;
}
</style>
